:root {
    --colors-base-white: 255, 255, 255;
    --colors-base-black: 0, 0, 0;
    --colors-base-transparent: 255, 255, 255, 0;
    --colors-gray-modern-25: 252, 252, 253;
    --colors-brand-25: var(--colors-blue-25);
    --colors-brand-50: var(--colors-blue-50);
    --colors-brand-100: var(--colors-blue-100);
    --colors-brand-200: var(--colors-blue-200);
    --colors-brand-300: var(--colors-blue-300);
    --colors-brand-400: var(--colors-blue-400);
    --colors-brand-500: var(--colors-blue-500);
    --colors-brand-600: var(--colors-blue-600);
    --colors-brand-700: var(--colors-blue-700);
    --colors-brand-800: var(--colors-blue-800);
    --colors-brand-900: var(--colors-blue-900);
    --colors-brand-950: var(--colors-blue-950);
    --colors-success-25: 246, 254, 249;
    --colors-success-50: 236, 253, 243;
    --colors-success-100: 220, 250, 230;
    --colors-success-200: 171, 239, 198;
    --colors-success-300: 117, 224, 167;
    --colors-success-400: 71, 205, 137;
    --colors-success-500: 23, 178, 106;
    --colors-success-600: 7, 148, 85;
    --colors-success-700: 6, 118, 71;
    --colors-success-800: 8, 93, 58;
    --colors-success-900: 7, 77, 49;
    --colors-success-950: 5, 51, 33;
    --colors-error-25: 255, 251, 250;
    --colors-error-50: 254, 243, 242;
    --colors-error-100: 254, 228, 226;
    --colors-error-200: 254, 205, 202;
    --colors-error-300: 253, 162, 155;
    --colors-error-400: 249, 112, 102;
    --colors-error-500: 240, 68, 56;
    --colors-error-600: 217, 45, 32;
    --colors-error-700: 180, 35, 24;
    --colors-error-800: 145, 32, 24;
    --colors-error-900: 122, 39, 26;
    --colors-error-950: 85, 22, 12;
    --colors-warning-25: 255, 252, 245;
    --colors-warning-50: 255, 250, 235;
    --colors-warning-100: 254, 240, 199;
    --colors-warning-200: 254, 223, 137;
    --colors-warning-300: 254, 200, 75;
    --colors-warning-400: 253, 176, 34;
    --colors-warning-500: 247, 144, 9;
    --colors-warning-600: 220, 104, 3;
    --colors-warning-700: 181, 71, 8;
    --colors-warning-800: 147, 55, 13;
    --colors-warning-900: 122, 46, 14;
    --colors-warning-950: 78, 29, 9;
    --colors-gray-modern-50: 249, 250, 251;
    --colors-gray-modern-100: 242, 244, 247;
    --colors-gray-modern-200: 228, 231, 236;
    --colors-gray-modern-300: 208, 213, 221;
    --colors-gray-modern-400: 152, 162, 179;
    --colors-gray-modern-500: 102, 112, 133;
    --colors-gray-modern-600: 71, 84, 103;
    --colors-gray-modern-700: 52, 64, 84;
    --colors-gray-modern-800: 24, 34, 48;
    --colors-gray-modern-900: 16, 24, 40;
    --colors-gray-modern-950: 12, 17, 29;
    --colors-gray-25: 253, 253, 253;
    --colors-gray-50: 250, 250, 250;
    --colors-gray-100: 245, 245, 245;
    --colors-gray-200: 233, 234, 235;
    --colors-gray-300: 213, 215, 218;
    --colors-gray-400: 164, 167, 174;
    --colors-gray-500: 113, 118, 128;
    --colors-gray-600: 83, 88, 98;
    --colors-gray-700: 65, 70, 81;
    --colors-gray-800: 37, 43, 55;
    --colors-gray-900: 24, 29, 39;
    --colors-gray-950: 10, 13, 18;
    --colors-gray-25: 250, 250, 250;
    --colors-gray-50: 247, 247, 247;
    --colors-gray-100: 240, 240, 241;
    --colors-gray-200: 236, 236, 237;
    --colors-gray-300: 206, 207, 210;
    --colors-gray-400: 148, 151, 156;
    --colors-gray-500: 133, 136, 142;
    --colors-gray-600: 97, 101, 108;
    --colors-gray-700: 55, 58, 65;
    --colors-gray-800: 34, 38, 47;
    --colors-gray-900: 19, 22, 27;
    --colors-gray-950: 12, 14, 18;
    --colors-gray-blue-25: 252, 252, 253;
    --colors-gray-blue-50: 248, 249, 252;
    --colors-gray-blue-100: 234, 236, 245;
    --colors-gray-blue-200: 213, 217, 235;
    --colors-gray-blue-300: 179, 184, 219;
    --colors-gray-blue-400: 113, 123, 188;
    --colors-gray-blue-500: 78, 91, 166;
    --colors-gray-blue-600: 62, 71, 132;
    --colors-gray-blue-700: 54, 63, 114;
    --colors-gray-blue-800: 41, 48, 86;
    --colors-gray-blue-900: 16, 19, 35;
    --colors-gray-blue-950: 13, 15, 28;
    --colors-gray-cool-25: 252, 252, 253;
    --colors-gray-cool-50: 249, 249, 251;
    --colors-gray-cool-100: 239, 241, 245;
    --colors-gray-cool-200: 220, 223, 234;
    --colors-gray-cool-300: 185, 192, 212;
    --colors-gray-cool-400: 125, 137, 176;
    --colors-gray-cool-500: 93, 107, 152;
    --colors-gray-cool-600: 74, 85, 120;
    --colors-gray-cool-700: 64, 73, 104;
    --colors-gray-cool-800: 48, 55, 79;
    --colors-gray-cool-900: 17, 19, 34;
    --colors-gray-cool-950: 14, 16, 27;
    --colors-gray-neutral-25: 252, 252, 253;
    --colors-gray-neutral-50: 249, 250, 251;
    --colors-gray-neutral-100: 243, 244, 246;
    --colors-gray-neutral-200: 229, 231, 235;
    --colors-gray-neutral-300: 210, 214, 219;
    --colors-gray-neutral-400: 157, 164, 174;
    --colors-gray-neutral-500: 108, 115, 127;
    --colors-gray-neutral-600: 77, 87, 97;
    --colors-gray-neutral-700: 56, 66, 80;
    --colors-gray-neutral-800: 31, 42, 55;
    --colors-gray-neutral-900: 17, 25, 39;
    --colors-gray-neutral-950: 13, 18, 28;
    --colors-gray-iron-25: 252, 252, 252;
    --colors-gray-iron-50: 250, 250, 250;
    --colors-gray-iron-100: 244, 244, 245;
    --colors-gray-iron-200: 228, 228, 231;
    --colors-gray-iron-300: 209, 209, 214;
    --colors-gray-iron-400: 160, 160, 171;
    --colors-gray-iron-500: 112, 112, 123;
    --colors-gray-iron-600: 81, 82, 92;
    --colors-gray-iron-700: 63, 63, 70;
    --colors-gray-iron-800: 38, 39, 43;
    --colors-gray-iron-900: 26, 26, 30;
    --colors-gray-iron-950: 19, 19, 22;
    --colors-gray-true-25: 252, 252, 252;
    --colors-gray-true-50: 247, 247, 247;
    --colors-gray-true-100: 245, 245, 245;
    --colors-gray-true-200: 229, 229, 229;
    --colors-gray-true-300: 214, 214, 214;
    --colors-gray-true-400: 163, 163, 163;
    --colors-gray-true-500: 115, 115, 115;
    --colors-gray-true-600: 82, 82, 82;
    --colors-gray-true-700: 66, 66, 66;
    --colors-gray-true-800: 41, 41, 41;
    --colors-gray-true-900: 20, 20, 20;
    --colors-gray-true-950: 15, 15, 15;
    --colors-gray-warm-25: 253, 253, 252;
    --colors-gray-warm-50: 250, 250, 249;
    --colors-gray-warm-100: 245, 245, 244;
    --colors-gray-warm-200: 231, 229, 228;
    --colors-gray-warm-300: 215, 211, 208;
    --colors-gray-warm-400: 169, 162, 157;
    --colors-gray-warm-500: 121, 113, 107;
    --colors-gray-warm-600: 87, 83, 78;
    --colors-gray-warm-700: 68, 64, 60;
    --colors-gray-warm-800: 41, 37, 36;
    --colors-gray-warm-900: 28, 25, 23;
    --colors-gray-warm-950: 23, 20, 18;
    --colors-moss-25: 250, 253, 247;
    --colors-moss-50: 245, 251, 238;
    --colors-moss-100: 230, 244, 215;
    --colors-moss-200: 206, 234, 176;
    --colors-moss-300: 172, 220, 121;
    --colors-moss-400: 134, 203, 60;
    --colors-moss-500: 102, 159, 42;
    --colors-moss-600: 79, 122, 33;
    --colors-moss-700: 63, 98, 26;
    --colors-moss-800: 51, 80, 21;
    --colors-moss-900: 43, 66, 18;
    --colors-moss-950: 26, 40, 11;
    --colors-green-light-25: 250, 254, 245;
    --colors-green-light-50: 243, 254, 231;
    --colors-green-light-100: 227, 251, 204;
    --colors-green-light-200: 208, 248, 171;
    --colors-green-light-300: 166, 239, 103;
    --colors-green-light-400: 133, 225, 58;
    --colors-green-light-500: 102, 198, 28;
    --colors-green-light-600: 76, 163, 13;
    --colors-green-light-700: 59, 124, 15;
    --colors-green-light-800: 50, 98, 18;
    --colors-green-light-900: 43, 83, 20;
    --colors-green-light-950: 21, 41, 10;
    --colors-green-25: 246, 254, 249;
    --colors-green-50: 237, 252, 242;
    --colors-green-100: 211, 248, 223;
    --colors-green-200: 170, 240, 196;
    --colors-green-300: 115, 226, 163;
    --colors-green-400: 60, 203, 127;
    --colors-green-500: 22, 179, 100;
    --colors-green-600: 9, 146, 80;
    --colors-green-700: 8, 116, 67;
    --colors-green-800: 9, 92, 55;
    --colors-green-900: 8, 76, 46;
    --colors-green-950: 5, 46, 28;
    --colors-teal-25: 246, 254, 252;
    --colors-teal-50: 240, 253, 249;
    --colors-teal-100: 204, 251, 239;
    --colors-teal-200: 153, 246, 224;
    --colors-teal-300: 95, 233, 208;
    --colors-teal-400: 46, 211, 183;
    --colors-teal-500: 21, 183, 158;
    --colors-teal-600: 14, 147, 132;
    --colors-teal-700: 16, 117, 105;
    --colors-teal-800: 18, 93, 86;
    --colors-teal-900: 19, 78, 72;
    --colors-teal-950: 10, 41, 38;
    --colors-cyan-25: 245, 254, 255;
    --colors-cyan-50: 236, 253, 255;
    --colors-cyan-100: 207, 249, 254;
    --colors-cyan-200: 165, 240, 252;
    --colors-cyan-300: 103, 227, 249;
    --colors-cyan-400: 34, 204, 238;
    --colors-cyan-500: 6, 174, 212;
    --colors-cyan-600: 8, 138, 178;
    --colors-cyan-700: 14, 112, 144;
    --colors-cyan-800: 21, 91, 117;
    --colors-cyan-900: 22, 76, 99;
    --colors-cyan-950: 13, 45, 58;
    --colors-blue-light-25: 245, 251, 255;
    --colors-blue-light-50: 240, 249, 255;
    --colors-blue-light-100: 224, 242, 254;
    --colors-blue-light-200: 185, 230, 254;
    --colors-blue-light-300: 124, 212, 253;
    --colors-blue-light-400: 54, 191, 250;
    --colors-blue-light-500: 11, 165, 236;
    --colors-blue-light-600: 0, 134, 201;
    --colors-blue-light-700: 2, 106, 162;
    --colors-blue-light-800: 6, 89, 134;
    --colors-blue-light-900: 11, 74, 111;
    --colors-blue-light-950: 6, 44, 65;
    --colors-blue-25: 245, 250, 255;
    --colors-blue-50: 239, 248, 255;
    --colors-blue-100: 209, 233, 255;
    --colors-blue-200: 178, 221, 255;
    --colors-blue-300: 132, 202, 255;
    --colors-blue-400: 83, 177, 253;
    --colors-blue-500: 46, 144, 250;
    --colors-blue-600: 17, 109, 248;
    --colors-blue-700: 23, 92, 211;
    --colors-blue-800: 24, 73, 169;
    --colors-blue-900: 25, 65, 133;
    --colors-blue-950: 16, 42, 86;
    --colors-blue-dark-25: 245, 248, 255;
    --colors-blue-dark-50: 239, 244, 255;
    --colors-blue-dark-100: 209, 224, 255;
    --colors-blue-dark-200: 178, 204, 255;
    --colors-blue-dark-300: 132, 173, 255;
    --colors-blue-dark-400: 82, 139, 255;
    --colors-blue-dark-500: 41, 112, 255;
    --colors-blue-dark-600: 21, 94, 239;
    --colors-blue-dark-700: 0, 78, 235;
    --colors-blue-dark-800: 0, 64, 193;
    --colors-blue-dark-900: 0, 53, 158;
    --colors-blue-dark-950: 0, 34, 102;
    --colors-indigo-25: 245, 248, 255;
    --colors-indigo-50: 238, 244, 255;
    --colors-indigo-100: 224, 234, 255;
    --colors-indigo-200: 199, 215, 254;
    --colors-indigo-300: 164, 188, 253;
    --colors-indigo-400: 128, 152, 249;
    --colors-indigo-500: 97, 114, 243;
    --colors-indigo-600: 68, 76, 231;
    --colors-indigo-700: 53, 56, 205;
    --colors-indigo-800: 45, 49, 166;
    --colors-indigo-900: 45, 50, 130;
    --colors-indigo-950: 31, 35, 91;
    --colors-violet-25: 251, 250, 255;
    --colors-violet-50: 245, 243, 255;
    --colors-violet-100: 236, 233, 254;
    --colors-violet-200: 221, 214, 254;
    --colors-violet-300: 195, 181, 253;
    --colors-violet-400: 164, 138, 251;
    --colors-violet-500: 135, 91, 247;
    --colors-violet-600: 120, 57, 238;
    --colors-violet-700: 105, 39, 218;
    --colors-violet-800: 87, 32, 183;
    --colors-violet-900: 73, 28, 150;
    --colors-violet-950: 46, 18, 94;
    --colors-purple-25: 250, 250, 255;
    --colors-purple-50: 244, 243, 255;
    --colors-purple-100: 235, 233, 254;
    --colors-purple-200: 217, 214, 254;
    --colors-purple-300: 189, 180, 254;
    --colors-purple-400: 155, 138, 251;
    --colors-purple-500: 122, 90, 248;
    --colors-purple-600: 105, 56, 239;
    --colors-purple-700: 89, 37, 220;
    --colors-purple-800: 74, 31, 184;
    --colors-purple-900: 62, 28, 150;
    --colors-purple-950: 39, 17, 95;
    --colors-fuchsia-25: 254, 250, 255;
    --colors-fuchsia-50: 253, 244, 255;
    --colors-fuchsia-100: 251, 232, 255;
    --colors-fuchsia-200: 246, 208, 254;
    --colors-fuchsia-300: 238, 170, 253;
    --colors-fuchsia-400: 228, 120, 250;
    --colors-fuchsia-500: 212, 68, 241;
    --colors-fuchsia-600: 186, 36, 213;
    --colors-fuchsia-700: 159, 26, 177;
    --colors-fuchsia-800: 130, 24, 144;
    --colors-fuchsia-900: 111, 24, 119;
    --colors-fuchsia-950: 71, 16, 76;
    --colors-pink-25: 254, 246, 251;
    --colors-pink-50: 253, 242, 250;
    --colors-pink-100: 252, 231, 246;
    --colors-pink-200: 252, 206, 238;
    --colors-pink-300: 250, 167, 224;
    --colors-pink-400: 246, 112, 199;
    --colors-pink-500: 238, 70, 188;
    --colors-pink-600: 221, 37, 144;
    --colors-pink-700: 193, 21, 116;
    --colors-pink-800: 158, 22, 95;
    --colors-pink-900: 133, 22, 81;
    --colors-pink-950: 78, 13, 48;
    --colors-ros--25: 255, 245, 246;
    --colors-ros--50: 255, 241, 243;
    --colors-ros--100: 255, 228, 232;
    --colors-ros--200: 254, 205, 214;
    --colors-ros--300: 254, 163, 180;
    --colors-ros--400: 253, 111, 142;
    --colors-ros--500: 246, 61, 104;
    --colors-ros--600: 227, 27, 84;
    --colors-ros--700: 192, 16, 72;
    --colors-ros--800: 161, 16, 67;
    --colors-ros--900: 137, 18, 62;
    --colors-ros--950: 81, 11, 36;
    --colors-orange-dark-25: 255, 249, 245;
    --colors-orange-dark-50: 255, 244, 237;
    --colors-orange-dark-100: 255, 230, 213;
    --colors-orange-dark-200: 255, 214, 174;
    --colors-orange-dark-300: 255, 156, 102;
    --colors-orange-dark-400: 255, 105, 46;
    --colors-orange-dark-500: 255, 68, 5;
    --colors-orange-dark-600: 230, 46, 5;
    --colors-orange-dark-700: 188, 27, 6;
    --colors-orange-dark-800: 151, 24, 12;
    --colors-orange-dark-900: 119, 26, 13;
    --colors-orange-dark-950: 87, 19, 10;
    --colors-orange-25: 254, 250, 245;
    --colors-orange-50: 254, 246, 238;
    --colors-orange-100: 253, 234, 215;
    --colors-orange-200: 249, 219, 175;
    --colors-orange-300: 247, 178, 122;
    --colors-orange-400: 243, 135, 68;
    --colors-orange-500: 239, 104, 32;
    --colors-orange-600: 224, 79, 22;
    --colors-orange-700: 185, 56, 21;
    --colors-orange-800: 147, 47, 25;
    --colors-orange-900: 119, 41, 23;
    --colors-orange-950: 81, 28, 16;
    --colors-yellow-25: 254, 253, 240;
    --colors-yellow-50: 254, 251, 232;
    --colors-yellow-100: 254, 247, 195;
    --colors-yellow-200: 254, 238, 149;
    --colors-yellow-300: 253, 226, 114;
    --colors-yellow-400: 250, 197, 21;
    --colors-yellow-500: 234, 170, 8;
    --colors-yellow-600: 202, 133, 4;
    --colors-yellow-700: 161, 92, 7;
    --colors-yellow-800: 133, 74, 14;
    --colors-yellow-900: 113, 59, 18;
    --colors-yellow-950: 84, 44, 13;
    --spacing-0: 0px;
    --spacing-px: 1px;
    --spacing-0-5: 2px;
    --spacing-1: 4px;
    --spacing-1-5: 6px;
    --spacing-2: 8px;
    --spacing-2-5: 10px;
    --spacing-3: 12px;
    --spacing-4: 16px;
    --spacing-5: 20px;
    --spacing-6: 24px;
    --spacing-8: 32px;
    --spacing-10: 40px;
    --spacing-12: 48px;
    --spacing-16: 64px;
    --spacing-20: 80px;
    --spacing-24: 96px;
    --spacing-32: 128px;
    --spacing-40: 160px;
    --spacing-48: 192px;
    --spacing-56: 224px;
    --spacing-64: 256px;
    --spacing-80: 320px;
    --spacing-96: 384px;
    --spacing-120: 480px;
    --spacing-140: 560px;
    --spacing-160: 640px;
    --spacing-180: 720px;
    --spacing-192: 768px;
    --spacing-256: 1024px;
    --spacing-320: 1280px;
    --spacing-360: 1440px;
    --spacing-400: 1600px;
    --spacing-480: 1920px;
}
