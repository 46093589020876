@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import "./primitives.css";

  :root {
    --color-primary: #116df8;
    --color-primary-lightest: #f1f3f6;
    --color-primary-lighter: #bcd7ff;
    --color-primary-light: #add3ff;
    --color-primary-dark: #62708e;
    --color-primary-darker: #262b43;
    --color-primary-darkest: #161a2e;
    --color-primary-black: #0e1227;

    --color-white: #fff;
    --color-red: #f04438;
    --color-orange: #f79009;
    --color-green: #17b26a;
    --color-black: #000;
    --color-yellow: #fac515;

    --color-gray-lighter: #fafafa;
    --color-gray-light: #e4e7e9;
    --color-gray: #c3c6c9;
    --color-gray-dark: #83888c;
    --color-gray-darker: #6e6e6e;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    line-height: 1;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  label,
  input,
  select,
  body,
  button {
    font-style: normal;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  label,
  button {
    font-weight: 600;
  }

  h1 {
    font-size: 1.875rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  h4 {
    font-size: 1.125rem;
  }

  h5 {
    font-size: 1.0625rem;
  }

  h6 {
    font-size: 0.875rem;
  }

  label {
    font-size: 0.875rem;
    line-height: 1;
  }

  * {
    letter-spacing: 0.03rem;
  }

  html,
  body,
  #__next {
    @apply m-0 h-full w-full p-0;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--color-gray);
    border-radius: 10px;
  }

  .dark ::-webkit-scrollbar {
    background-color: #161a2e;
    width: 8px;
    height: 6px;
  }

  .dark ::-webkit-scrollbar-thumb {
    background-color: #62708e;
    border-radius: 1rem;
  }

  .dark ::-webkit-scrollbar-track {
    background-color: #262b43;
  }


  @media (max-width: 768px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }
}

/* Need to hide pactsafe title, it's loaded by the pactsafe script */
#tos-container .ps-contract-title {
  display: none !important;
}
